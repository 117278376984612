<template>
  <div class="message">
    <div class="header">
      <div class="title">留言版</div>
      <div class="icon">
        <el-icon v-if="show" :size="30" color="#ffffff" @click="show = false"><ArrowUp /></el-icon>
        <el-icon v-else :size="30" color="#ffffff" @click="show = true"><ArrowDown /></el-icon>
      </div>
    </div>
    <transition name="fade">
      <div class="content" v-if="show">
        <h1>广州怡水水务科技有限公司 020-31704024</h1>
        <el-form ref="formRef" :model="form">
          <el-form-item prop="content">
            <el-input
              type="textarea"
              v-model="form.content"
              :rows="3"
              placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"
            ></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input type="text" v-model="form.name" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input type="text" v-model="form.phone" placeholder="电话"></el-input>
          </el-form-item>
          <el-row>
            <el-col>
              <el-button type="primary" @click="submitForm(formRef)">提交</el-button></el-col
            >
          </el-row>
        </el-form>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ArrowUp, ArrowDown } from '@element-plus/icons-vue'
import { message } from '@/api/index'
import { ElMessage } from 'element-plus'

export default {
  components: {
    ArrowUp,
    ArrowDown
  },
  setup() {
    const form = reactive({
      name: null,
      phone: null,
      content: null
    })
    const show = ref(true)
    const formRef = ref(null)

    const submitForm = formRef => {
      if (!formRef) return
      formRef.validate((vaild, fieIds) => {
        if (vaild) {
          console.log(vaild)
          message(form)
            .then(res => {
              ElMessage({
                message: '提交成功',
                type: 'success'
              })
              // 清空
              formRef.resetFields()
            })
            .catch(err => {
              ElMessage.error('提交失败')
            })
        } else {
          console.log(fieIds)
        }
      })
    }
    return {
      form,
      show,
      submitForm,
      formRef
    }
  }
}
</script>

<style scoped lang="scss">
.message {
  width: 240px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  border-radius: 7px;
  overflow: hidden;
  z-index: 999;
  .header {
    height: 40px;
    background-color: #6696ff;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }
  .content {
    background-color: #fff;
    padding: 10px;
    overflow: hidden;
    border-radius: 0 0 7px 7px;
    border: 1px solid #d4d4d4;
    > h1 {
      padding-bottom: 10px;
      line-height: 20px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
