import request from "@/utils/request";

export const findGoods = () => {
  return request('/product/productClass/list', 'get')
}

export const findInfo = (id) => {
  return request(`/product/product/${id}`, 'get')
}
export const goodsByClassId = (classId) => {
  return request(`/product/product/listByClass?classId=${classId}&undercarriage=0`, 'get')
}
export const findDescribe = (id) => {
  return request(`/product/productDescription/list`, 'get', {
    id
  })
}

/**
 * 获取产品优势
 * @param {*} id 
 * @returns 
 */
export const findSup = (id) => {
  return request('/product/productAdvantage/list', 'get', {
    productId: id
  })
}