import request from "@/utils/request";
import axios from 'axios'
/**
 * 查询门店的城市分布
 * @returns 
 */
export const findStore = () => {
  return request('/store/store/listCity', 'get')
}

/**
 * 门店城市id
 * @param {*} id 
 * @returns 
 */
export const findStoreDetail = (id) => {
  return request(`/distribution/distributionStore/${id}`, 'get')
}

export const findList = (id) => {
  return request('/product/product/list', 'get', {
    classIds: id
  })
}
export const findList2 = () => {
  return request('/product/product/list', 'get')
}

export const findImgList = (id) => {
  return request('/product/productImage/list', 'get', {
    productId: id
  })
}

export const newsList = (id) => {
  return request('/news/news/list', 'get', {
    id
  })
}

export const newsType = () => {
  return request('/news/newsType/list', 'get')
}

export const message = ({
  name,
  phone,
  email,
  remark,
  content
}) => {
  return request('/customer/message', 'post', {
    name,
    phone,
    email,
    remark,
    content
  })
}

export const typeList = () => {
  return request('/customer/serviceType/list', 'get')
}

export const subInfo = ({
  customerName,
  customerPhone,
  serviceId,
  customerAddress,
  remark
}) => {
  return request('/customer/customer', 'post', {
    customerName,
    customerPhone,
    serviceId,
    customerAddress,
    remark
  })
}

export const newDetails = (id) => {
  return request(`/news/news/${id}`, 'get')
}

export const zp = () => {
  return request('/signboard/signboard/list?type=1', 'get')
}


//查询ip
export const getIp = () => {
  // let result = "127.0.0.1"
  return request("/socket/ip", 'get')
};

// 查询产品图片详细
export const getProductImage = (id) => {
  return request('/product/productImage/' + id, 'get')
}


// 查询招牌管理详细
export const getSignboard = (id) => {
  return request('/signboard/signboard/' + id, 'get')
}


export const listProductImage = () => {
  return request('/product/productImage/list?productId=0', 'get')
}
export const employeeText = () => {
  return request('/product/productImage/list?productId=7', 'get')
}

export const listProductImage2 = () => {
  return request('/product/productImage/list?productId=1', 'get')
}


// 查询项目信息列表
export const listProject = () => {
  return request('/project/project/list', 'get')

}


// 查询图片资源列表
export function listImage(query) {
  return request(`/image/image/list?fileType=${query}`, 'get', )
}

export function listAbout() {
  return request('/signboard/signboard/list?type=5', 'get', )
}