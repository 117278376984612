import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'

import {
  getProductImage
} from "@/api/index";

const Home = () => import('@/views/home')
const Brief = () => import('@/views/brief')
const Brand = () => import('@/views/brand')
const Employee = () => import('@/views/employee')
const Honour = () => import('@/views/honour')
const Goods = () => import('@/views/goods')
const Product = () => import('@/views/product')
const Product2 = () => import('@/views/product2')
const Product3 = () => import('@/views/product-copy1')
const Product4 = () => import('@/views/product-copy2')
const Water = () => import('@/views/water')
const News = () => import('@/views/news')
const JoinUs = () => import('@/views/joinUs')
const Sub = () => import('@/views/sub')
const Store = () => import('@/views/store')
const Order = () => import('@/views/order')
const Message = () => import('@/views/message')
const GoodsList = () => import('@/views/goods-list')
const GoodsDetail = () => import('@/views/goods-detail')
const Superiority = () => import('@/views/superiority')
const Intro = () => import('@/views/intro')
const Kf = () => import('@/views/kf')
const NewsDeatil = () => import('@/views/newsdetail')
const Contact = () => import('@/views/contact')
const Walk = () => import('@/views/walk')
const Serve = () => import('@/views/serve')
const Publicgood = () => import('@/views/public-good')
const Sth = () => import('@/views/sth')
const Flow = () => import('@/views/flow')
const Warm = () => import('@/views/warm')
const routes = [

  {
    path: '/',
    component: Home,
    meta: {
      title: '广州怡水水务科技有限公司官网'
    }
  },
  {
    path: '/brief',
    component: Brief,
    meta: {
      title: '工程案例'
    }
  },
  {
    path: '/brand',
    component: Brand,
    meta: {
      title: '品牌历程'
    }
  },
  {
    path: '/employee',
    component: Employee,
    meta: {
      title: '员工天地'
    }
  },
  {
    path: '/honour',
    component: Honour,
    meta: {
      title: '品牌荣誉'
    }
  },
  {
    path: '/goods',
    component: Goods,
    meta: {
      title: '净水器产品'
    }
  },
  {
    path: '/product/:id',
    component: Product3,
  },
  {
    path: '/product2',
    component: Product2,

  },
  // {
  //   path: '/product/3',
  //   component: Product3,
  // },
  {
    path: '/product/4',
    component: Product4,
  },

  {
    path: '/water',
    component: Water,
    meta: {
      title: '全屋净水'
    }
  },
  {
    path: '/news',
    component: News,
    meta: {
      title: '合作客户'
    }
  },
  {
    path: '/newsdetail/:id/:title',
    component: NewsDeatil,
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/joinus',
    component: JoinUs,
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/sub',
    component: Sub,
    meta: {
      title: '加盟申请'
    }
  },
  {
    path: '/store',
    component: Store,
    meta: {
      title: '门店分布'
    }
  },
  {
    path: '/order',
    component: Order,
    meta: {
      title: '一键预约'
    }
  },
  {
    path: '/message',
    component: Message,
    meta: {
      title: '发布留言'
    }
  },
  {
    path: '/list/:id/:title',
    component: GoodsList,
    name: 'list',
    meta: {
      title: '品牌历程'
    }
  },
  {
    path: '/goodsdetail/:id',
    component: GoodsDetail,
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/sup',
    component: Superiority,
    meta: {
      title: '加盟怡水'
    }
  },
  {
    path: '/intro',
    component: Intro,
    meta: {
      title: '品牌介绍'
    }
  },
  {
    path: '/kf',
    component: Kf,
    meta: {
      title: '在线客服'
    }
  },
  {
    path: '/contact',
    component: Contact,
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/walk',
    component: Walk,
    meta: {
      title: '走进怡水'
    }
  },
  {
    path: '/serve',
    component: Serve,
    meta: {
      title: '怡水服务'
    }
  },
  {
    path: '/sth',
    component: Sth,
    meta: {
      title: '服务承诺'
    }
  },
  {
    path: '/flow',
    component: Flow,
    meta: {
      title: '服务流程'
    }
  },
  {
    path: '/warm',
    component: Warm,
    meta: {
      title: '温暖瞬间'
    }
  },
  {
    path: '/publicgood',
    component: Publicgood,
    meta: {
      title: '爱升公益'
    }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})
/**
 * 路由前置守卫
 */

router.beforeEach(async (to, from, next) => {
  // 从后端获取网页标题
  getProductImage(21).then(res => {
    const title = res.data.imageAddress
    // 将网页标题设置为获取到的标题
    to.meta.title = title
    document.title = title || '广州怡水水务科技有限公司'

  })
  next()
})

//原来的
// router.beforeEach((to, form, nuxt) => {
//   console.log(to);
//   if (to.fullPath == '/product/1') {
//     document.title = '怡水2S'
//   } else if (to.fullPath == '/product2') {
//     document.title = '工程战斗机'
//   } else {
//     document.title = to.meta.title
//   }
//   nuxt()
// })
export default router