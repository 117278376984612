<template>
  <div class="footer-container">
    <el-row align="middle" style="height: 100%">
      <!-- <el-col span="2">
        <div class="qr-code">
          <img style="height: 100px; width: 100px" :src="logo" alt="" />
        </div>
      </el-col> -->
      <el-col span="6" :offset="10">
        <div class="info">
          公司名称：广州怡水水务科技有限公司<br />
          联系地址：广州市黄埔区东明三路18号智造谷创新园D栋<br />
          工厂地址：广东省江门市潮边工业区1号<br />
          企业邮箱：gzyssw@QQ.COM <br />
          网站备案号：<a
            class="beian"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002382"
            >44011202002382</a
          >
          <br />
          ICP主体备案号:<a class="beian" href="https://beian.miit.gov.cn/">
            粤ICP备2023040373号</a
          >
          <br />
        </div>
      </el-col>
      <el-col span="4" :offset="5">
        <div class="number">
          <img src="@/assets/images/number.webp" alt="" />
          <div style="font-size: 10px">020-31704024</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getProductImage } from "@/api/index";

export default {
  name: "Footer-Layout",
  setup() {
    let logo = "";
    const getLogo = () => {
      getProductImage(20).then((res) => {
        logo = res.data.imageAddress;
      });
    };
    getLogo();
    info: [];
    return {
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
.footer-container {
  width: 1190px;
  height: 130px;
  margin: 0 auto;
  // background-color: #4cf1d3;
  // background-color : #009fe9;

  .info {
    line-height: 15px;
    color: #fff;
    font-size: 10px;
    .beian {
      color: #fff;
      text-decoration: none;
    }
  }
  .number {
    display: flex;

    align-items: center;
    color: #fff;
    font-size: 10px;
    position: relative;
    right: 0;
  }
}
</style>
