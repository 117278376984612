<template>
  <div class="container">
    <div class="header">
      <p>
        广州怡水水务科技有限公司
        <span @click="visible"
          ><el-icon size="26"><CloseBold /></el-icon
        ></span>
      </p>
      <p>020-31704024</p>
    </div>
    <div class="main" ref="main">
      <div
        v-for="(item, i) in messageList"
        :key="i"
        :class="[item.fromUser === message.fromUser ? 'right' : 'left']"
      >
        <p style="padding-bottom: 5px">{{ item.time }}</p>
        <p class="msg">
          {{ item.msg || item.message }}
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="type">
        <!-- <span @click="drawer = true">文件</span> -->
        <!-- <span @click="drawer = true">图片</span> -->
      </div>
      <div class="text">
        <textarea
          v-model="message.msg"
          cols="30"
          rows="2"
          autofocus
          class="t"
          placeholder="点击输入内容"
          @keyup.enter.native="submit"
          style="height: 30px; padding-left: 5px"
        ></textarea>
      </div>
      <div
        style="
          text-align: right;
          padding: 5px 10px;
          width: 100px;
          position: absolute;
          top: 40px;
          right: 0;
        "
      >
        <el-button type="primary" @click="submit" size="large">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, nextTick } from "vue";
import SnowflakeId from "snowflake-id";
import { CloseBold } from "@element-plus/icons-vue";
import { getIp } from "@/api/index";
export default {
  components: {
    CloseBold,
  },
  setup(props, { emit }) {
    const snowflake = new SnowflakeId({
      mid: 42,
      offset: (2019 - 1970) * 31536000 * 1000,
    });

    const drawer = ref(false);
    let messageList = ref([]);

    const message = reactive({
      fromUser: "", //你的id
      toUser: "server", //发给哪个用户 id
      msg: "", //消息载体
      time: "",
    });
    let ws;
    getIp().then((res) => {
      message.fromUser = res + "";
      ws = new WebSocket(`wss://admin.yssw-gz.com:443/websocket/${message.fromUser}`);
      const websocket = () => {
        // 监听连接成功
        ws.onopen = () => {
          console.log("连接服务端WebSocket成功");
          // 赋值id
        };
        // 监听服务端消息(接收消息)
        ws.onmessage = (res) => {
          let data = JSON.parse(res.data);
          console.log("收到的消息：", data);
          // 接收到服务端id
          // message.toUser = data.serverId || data.serverId
          data.time = format();
          if (data?.msg) {
            let htmlStr = data.msg;
            let pattern = /<.*?p.*?>/g; // 匹配p标签的正则表达式
            let result = htmlStr.replace(pattern, ""); // 将匹配到的p标签替换为空字符串
            data.msg = result;
          }
          messageList.value.push(data);
        };
        // 监听连接失败
        ws.onerror = () => {
          console.log("连接失败，正在重连...");
          websocket();
        };
        // 监听连接关闭
        ws.onclose = () => {
          console.log("连接关闭");
        };
      };
      websocket();
    });

    // console.log(message.fromUser);
    const main = ref(null);

    // let ws = new WebSocket(`ws://localhost:5555/websocket/${message.fromUser}`);
    // let ws = new WebSocket(`ws://192.168.0.100:5555/websocket/${message.fromUser}`)

    const submit = () => {
      if (message.msg === "") return;
      // 深拷贝
      message.time = format();
      let newMsg = JSON.stringify(message);
      messageList.value.push(JSON.parse(newMsg));
      ws.send(newMsg); // send 方法给服务端发送消息
      message.msg = "";
      nextTick(() => {
        main.value.scrollTo({ top: main.value.scrollHeight, behavior: "smooth" });
      });
    };

    const format = () => {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let d = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();

      if (month < 10) {
        month = "0" + month;
      }

      if (d < 10) {
        d = "0" + d;
      }

      if (hour < 10) {
        hour = "0" + hour;
      }

      if (minute < 10) {
        minute = "0" + minute;
      }

      if (second < 10) {
        second = "0" + second;
      }

      return year + "-" + month + "-" + d + " " + hour + ":" + minute + ":" + second;
    };
    const visible = () => {
      emit("showKF", false);
    };
    return {
      drawer,
      submit,
      message,
      main,
      messageList,
      visible,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  // width: 400px;
  height: 333px;
  margin: 0px auto;
  // border: 2px solid #ddd;
  box-shadow: 0 0 5px #ddd;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  // background: #b7d5fe;
  margin-top: 180px;
  // position: fixed;
  // top: 50%;
  // right: 20px;
  // transform: translateY(-90%);

  .header {
    width: 100%;
    height: 50px;
    color: #fff;
    background-color: #6696ff !important;
    background-image: none !important;
    p {
      text-align: left;
      padding: 5px 0;
      padding-left: 20px;
      display: flex;
      align-items: center;
      position: relative;
      > span {
        display: inline-block;
        margin-left: 10px;
        position: absolute;
        right: 20px;
        top: 10px;
      }
    }
  }
  .main {
    width: 100%;
    // height: 34vh;
    height: 168px;
    background-color: #fff;
    // background-color: rgba(228, 249, 255, 0.4);

    overflow: auto;
    padding: 10px 0;
  }
  .main::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .main::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #ffffff;
  }
  .main::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #6696ff;
  }
  .footer {
    width: 100%;
    // height: 130px;
    border-top: 1px solid rgb(237, 237, 237);
    position: relative;
    background: #b7d5fe;
    height: 95px;
    .type {
      span {
        margin: 0 20px;
      }
    }
    .text {
      textarea {
        width: 99%;
        height: 30px;
        max-height: 100px;
        resize: none;
        // border: 1px solid rgb(224, 224, 224) !important;
      }
      input[type="text"],
      textarea {
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        outline: none;
        // padding: 3px 0px 3px 3px;
        // margin: 1px 1px 3px 0px;
        // border: 1px solid #ddd;
        background: #b7d5fe;
        border: none;
      }

      input[type="text"]:focus,
      textarea:focus {
        // box-shadow: 0 0 5px rgb(193, 193, 193);
        // padding: 3px 0px 3px 3px;
        // margin: 1px 1px 3px 0px;
        // border: 1px solid rgb(191, 191, 191);
      }
      textarea::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .left {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .right {
    text-align: right;
    padding-right: 20px;
    padding-bottom: 10px;
  }
  .msg {
    text-align: left;
    word-break: break-all;
    border-radius: 4px;
    border-radius: 8px 0 8px 8px;
    background: rgba(100, 147, 252, 0.16);
    line-height: 22px;
    display: inline-block;
    max-width: 80%;
    padding: 12px;
    font-size: 14px;
    margin-top: 0;
  }
}
</style>
