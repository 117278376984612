import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入el
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 重置样式
import 'normalize.css'
import '@/assets/style/reset.css'
createApp(App).use(store).use(router).use(ElementPlus).use(ElementPlusIconsVue).mount('#app')
