import Header from '@/layout/header.vue';
<template>
  <div class="header-container">
    <el-row align="middle" style="height: 100%">
      <!-- <el-col :span="6" :lg="{ span: 4 }">
        <div class="logo">
          <img :src="logo" alt="" />
        </div>
      </el-col> -->
      <el-col :span="10" :lg="{ span: 13, offset: 12 }" style="height: 100%">
        <div class="nav">
          <ul>
            <li class="dropdown" v-for="item in navList" :key="item.id">
              <router-link :style="style" :to="item.path">{{ item.name }}</router-link>
              <div class="dropdown-content">
                <router-link :to="son.path" v-for="son in item.children" :key="son.id">{{
                  son.name
                }}</router-link>
              </div>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getProductImage } from "@/api/index";
import { findInfo, findGoods } from "@/api/goods";
import { ref } from "vue";
export default {
  name: "Header-Layout",
  setup() {
    // let logo = require("../assets/images/logo.png");
    let logo = "";
    const style = ref({});

    const getMenuSet = () => {
      findInfo(32).then((res) => {
        style.value = {
          "text-decoration": "none",
          color: res.data.productMaterial,
          "font-size": res.data.productName + "px",
        };
      });
    };

    const getLogo = () => {
      getProductImage(20).then((res) => {
        logo = res.data.imageAddress;
      });
    };
    getMenuSet();
    getLogo();
    // 导航栏

    const navList = ref([]);
    const navtemp = [
      {
        id: "01",
        name: "首页",
        path: "/",
      },
      {
        id: "02",
        name: "走进怡水",
        path: "",
        children: [
          {
            id: "07",
            name: "公司简介",
            path: "/walk",
          },
          {
            id: "04",
            name: "企业文化",
            path: "/honour",
          },
          {
            id: "06",
            name: "爱升品牌",
            path: "/intro",
          },
          {
            id: "03",
            name: "荣誉资质",
            path: "/brand",
          },
          {
            id: "05",
            name: "员工天地",
            path: "/employee",
          },
        ],
      },
      {
        id: "03",
        name: "怡水产品",
        path: "",
        children: [],
      },
      {
        id: "08",
        name: "工程案例",
        path: "/brief",
      },

      {
        id: "06",
        name: "怡水服务",
        path: "",
        children: [
          {
            id: "01",
            name: "服务承诺",
            path: "/sth",
          },
          {
            id: "02",
            name: "服务流程",
            path: "/flow",
          },
          {
            id: "03",
            name: "温暖瞬间",
            path: "/warm",
          },
        ],
      },
      {
        id: "05",
        name: "加盟怡水",
        path: "",
        children: [
          {
            id: "01",
            name: "加盟申请",
            path: "/sub",
          },
          {
            id: "02",
            name: "关于我们",
            path: "/sup",
          },
        ],
      },

      {
        id: "07",
        name: "联系我们",
        path: "",
        children: [
          {
            id: "01",
            name: "一键预约",
            path: "/order",
          },
          {
            id: "02",
            name: "发布留言",
            path: "/message",
          },
          // {
          //   id: '03',
          //   name: '门店分布',
          //   path: '/store'
          // },
          {
            id: "04",
            name: "加入我们",
            path: "/joinus",
          },
        ],
      },
    ];
    findGoods().then((res) => {
      for (let i = 0; i < res.rows.length; i++) {
        let menu = {
          id: res.rows[i].id,
          name: res.rows[i].className,
          path: `/product/${res.rows[i].id}`,
        };
        navtemp[2].children.push(menu);
      }
      navList.value = navtemp;
    });
    return {
      navList,
      style,
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
.header-container {
  width: 1190px;
  height: 130px;
  // background-color: #4cf1d3;
  // background-color: #009fe9;
  margin: 0 auto;
  .logo {
    width: 226px;
    height: 100%;
    img {
      width: 98px;
      height: 93px;
    }
  }
  .nav {
    width: 100%;
    height: 100%;
    & > ul {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        text-decoration: none;
        color: #fff;
      }
      .dropdown {
        // padding: 0 10px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        .dropdown-content {
          min-width: 110px;
          position: absolute;
          top: 100px;
          // left: -20px;
          display: none;
          background-color: #fff;
          transition: all 1s ease-in-out;

          z-index: 99999;
          // border: 1px solid #e7e7e7;
          > a {
            display: block;
            // width: 100%;
            text-align: center;
            color: #009fe9;
            padding: 8px 10px;
            height: 40px;
            background: white;
            line-height: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        &:hover .dropdown-content {
          display: block;
        }
        & .dropdown-content a:hover {
          background-color: #009fe9;
          color: #fff;
          transition: all 0.3s ease-in-out;
        }
        // & .dropdown-content::before {
        //   content: '';
        //   width: 20px;
        //   height: 13px;
        //   display: block;
        //   background-color: rgb(255, 255, 255);
        //   -webkit-clip-path: polygon(0 100%, 50% 0, 100% 100%);
        //   clip-path: polygon(0 100%, 50% 0, 100% 100%);
        //   position: absolute;
        //   top: -13px;
        //   left: 10px;
        // }
        // & .dropdown-content:nth-child(1)::before {
        //   content: '';
        // }
      }
    }
  }
}
:global(.dropDownStyle .el-dropdown_menu) {
  margin-top: 10px;
}
:deep(.el-popper) {
  top: 20px !important;
}
.el-dropdown-link {
  text-decoration: none;
  color: #009fe9;
  font-size: 18px;
}
</style>
